import {
  PlatformEvent,
  EditorSDK,
  PanelResolveType,
  NotificationType,
  EditorPlatformApp,
} from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';
import { EditorScriptFlowAPI } from 'yoshi-flow-editor-runtime/build/esm/flow-api/EditorScript';
import Experiments from '@wix/wix-experiments';
import {
  withMembersArea,
  isMembersAreaInstalled,
  installRegisteredApps,
  registerMembersAreaApps,
} from '@wix/members-area-integration-kit';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { getOrganizationFullByAppDefAndInstance } from './core/oloApi';
import doesRestaurantUseCashier from '@wix/wixrest-utils/dist/doesRestaurantUseCashier';
import webBiLogger from '@wix/web-bi-logger';
import initSchemaLogger from '@wix/bi-logger-olo-client';
import uuid from 'uuid';
import { getOrganizationAndMenu } from '@wix/restaurants-client-logic';

const JULY_21_2021 = 1626825600000;
const biLogger = initSchemaLogger(webBiLogger)();

let useLogger = true;

export function enableLogger() {
  useLogger = true;
}

export function disableLogger() {
  useLogger = false;
}

function log(...str: string[]) {
  if (useLogger) {
    console.log('[Restaurants editor script]', ...str);
  }
}

let t: TranslationFunction;
let appToken: string;
let experiments: Experiments;

const LOYALTY_APP_ID = '553c79f3-5625-4f38-b14b-ef7c0d1e87df';
export const ORDERS_APP_ID = '13e8d036-5516-6104-b456-c8466db39542';
export const IFRAME_WIDGET_ID = '13e8d047-31b9-9c1f-4f89-48ba9430f838';
export const OOI_WIDGET_ID = '96254d42-7323-40cb-a7cb-b7c242019728';
const membersAreaAppDefIds = [MA_APP_IDS.MY_ADDRESSES, MA_APP_IDS.MY_WALLET];

async function upgradeIframeToOoi(editorSDK: EditorSDK, iframeComponent: any): Promise<boolean> {
  try {
    log('attempt to migrate to OOI');
    await editorSDK.document.transactions.runAndWaitForApproval('', () => {
      log('transaction started...');
      return editorSDK.document.components.data.update('', {
        componentRef: iframeComponent.componentRef,
        data: { widgetId: OOI_WIDGET_ID },
      });
    });
    log('successfully migrated to OOI');
    return true;
  } catch (e) {
    log('encountered an error!', e.message);
    return false;
  }
}

function openPromotionalPanel(editorSDK: EditorSDK): Promise<PanelResolveType> {
  log('open Promotional Panel');
  return editorSDK.editor.openPromotionalPanel('', {
    titleText: t('OnlineOrdering_UpdateModal_Title'),
    subtitleText: t('OnlineOrdering_UpdateModal_Subtitle'),
    contentArray: [
      t('OnlineOrdering_UpdateModal_BulletText1'),
      t('OnlineOrdering_UpdateModal_BulletText2'),
      t('OnlineOrdering_UpdateModal_BulletText3'),
    ],
    KBLinkText: t('OnlineOrdering_UpdateModal_LearnMore'),
    mainActionText: t('OnlineOrdering_UpdateModal_CTA'),
    helpId: 'b8dfe2f5-033a-4598-8d5a-e7e4ee979250',
  });
}

async function navigateToOnlineOrdersPage(editorSDK: EditorSDK, iframeComponent: any): Promise<boolean> {
  try {
    log('starting navigation to online orders page');
    const pageRef = await editorSDK.components.getPage('', {
      componentRef: iframeComponent.componentRef,
    });
    log('move to order-online page');
    editorSDK.document.pages.navigateTo('', { pageRef });
    return true;
  } catch (e) {
    log('encountered an error!', e.message);
    return false;
  }
}

async function upgradeOnlineOrdersToOoiForExistingUsersIfNeeded(
  editorSDK: EditorSDK,
  iframeComponent: any,
  isRestaurantUsingCashier: boolean,
  restaurantId: string,
  withAutoMigration: boolean,
): Promise<boolean> {
  if (!isRestaurantUsingCashier) {
    log('restaurant is not using cashier yet, if it is exsiting user - not initiating upgrade process');
    return false;
  }

  if (!(iframeComponent && iframeComponent.componentRef)) {
    log('iframeComponent was not found, not initiating upgrade process');
    return false;
  }

  if (experiments.enabled('specs.restaurants.OOI-migration-panel') && !withAutoMigration) {
    log('the experiment is on, open the promotional panel');
    let openPromotionalPanelResult;

    try {
      openPromotionalPanelResult = await openPromotionalPanel(editorSDK);
    } catch (e) {
      log('Unable to open promotional modal, aborting.');
    }

    if (openPromotionalPanelResult === PanelResolveType.MAIN_ACTION) {
      log('the "upgrade now" button is clicked');
      biLogger.ooiBannerClick({ type: 'ooiMigrationUpdateEditor', restaurantId });
      const navigationSuccess = await navigateToOnlineOrdersPage(editorSDK, iframeComponent);
      if (!navigationSuccess) {
        log('navigation to online orders page was failed');
        return false;
      }

      const upgradeSuccess = await upgradeIframeToOoi(editorSDK, iframeComponent);
      if (!upgradeSuccess) {
        log('upgrade to OOI was failed');
        return false;
      }
      log('upgrade successfully, show action notification');

      await editorSDK.editor.showUserActionNotification('', {
        message: t('OnlineOrdering_UpdateSuccessful_SuccessMsg'),
        type: NotificationType.Success,
        link: {
          caption: '',
        },
      });

      log('done! send a bi event that the site migrated to OOI');
      biLogger.siteMigratedToOoi({});
      return true;
    } else {
      log('user did not click "OK"');
      return false;
    }
  } else {
    log('experiment "specs.restaurants.OOI-migration-panel" is off or should use autoMigration');
    return false;
  }
}

export const editorReadyImpl = async (
  editorSDK: EditorSDK,
  appDefinitionId: any,
  platformOptions: any,
  flowAPI: EditorScriptFlowAPI,
) => {
  experiments = await flowAPI.getExperiments();
  t = flowAPI.translations.t;
  appToken = appDefinitionId;
  const { instanceId, applicationId, language, viewMode, metaSiteId, instance } = platformOptions.initialAppData;

  let organizationFull = await getOrganizationAndMenu(instance, undefined);

  if (!organizationFull || !organizationFull.restaurant) {
    organizationFull = await getOrganizationFullByAppDefAndInstance(instanceId, applicationId, language);
  }

  const doesUseCashier = doesRestaurantUseCashier(organizationFull.restaurant);

  biLogger.util.updateDefaults({
    oloSessionId: uuid.v4(),
    restaurantId: organizationFull.restaurant.id,
    viewMode,
    msid: metaSiteId,
    instanceId,
    appId: applicationId,
  });

  log('Fetching all components.');

  const allComponents = await editorSDK.components.getAllComponents(appDefinitionId);

  log('Fetching components data.');

  const allComponentStructures = await editorSDK.components.get(appDefinitionId, {
    componentRefs: allComponents,
    properties: ['data'],
  });

  log('Searching for online-orders iframe.');

  const iframeComponent = allComponentStructures.find(
    (cs: any) => cs?.data?.appDefinitionId === ORDERS_APP_ID && cs?.data?.widgetId === IFRAME_WIDGET_ID,
  );
  log("checking if the site's creation time is after 21.7.2021");
  const isCreationTimeAfterJuly21 = organizationFull.restaurant.created >= JULY_21_2021;

  const isTemplate = !organizationFull.fromSite;
  log('checking if the siteis still a template: ' + isTemplate);

  log('checking if the experiment (specs.restaurants.OOI-migration-panel) is on and if we need auto migration');
  const withAutoMigration =
    experiments.enabled('specs.restaurants.olo-client-migration') ||
    ((isCreationTimeAfterJuly21 || isTemplate) && experiments.enabled('specs.restaurants.migrate-ooi-existing-users'));

  const isUpgradedToOoi = await upgradeOnlineOrdersToOoiForExistingUsersIfNeeded(
    editorSDK,
    iframeComponent,
    doesUseCashier,
    organizationFull.restaurant.id,
    withAutoMigration,
  );

  if (withAutoMigration && !isUpgradedToOoi) {
    if (iframeComponent && iframeComponent.componentRef) {
      log(`found iframe component! (id = "${iframeComponent.componentRef.id}")`);

      log('replacing data.widgetId to OOI...');

      const success = await upgradeIframeToOoi(editorSDK, iframeComponent);

      if (!success) {
        throw new Error('OOI migration was not successful - aborting');
      }

      log('done!');
    } else {
      log('iframe component was not found.');
    }
  }

  if (experiments.enabled('specs.restaurants.olo-client-members-area')) {
    log('Member area experiment is enabled, attempting to set settings...');

    const oloComponent = allComponentStructures.find((cs: any) => cs?.data?.appDefinitionId === ORDERS_APP_ID);

    if (oloComponent && oloComponent.componentRef) {
      log(`found OLO component! (id = "${oloComponent.componentRef.id}")`);

      const publicData = await editorSDK.document.tpa.data.getAll('token', { compRef: oloComponent.componentRef });

      log('is integrated to members area:', String(Boolean(publicData?.COMPONENT?.hasMembersAreaIntegration)));

      if (!publicData?.COMPONENT?.hasMembersAreaIntegration) {
        try {
          log('setting hasMembersAreaIntegration value to true');
          await editorSDK.document.transactions.runAndWaitForApproval('', () => {
            return editorSDK.document.tpa.data.set('', {
              compRef: oloComponent.componentRef,
              scope: 'COMPONENT',
              key: 'hasMembersAreaIntegration',
              value: true,
            });
          });
          log('hasMembersAreaIntegration value was set to true successfully');

          if (await isMembersAreaInstalled()) {
            log('members area is already installed, adding required modules...');
            await registerMembersAreaApps(membersAreaAppDefIds);
            await installRegisteredApps();
            log('required modules added successfully');
          }
        } catch (e) {
          log('encountered an error while setting hasMembersAreaIntegration value!', e.message);
        }
      }
    } else {
      log('OLO component was not found!');
    }
  } else {
    log('Member area experiment is disabled.');
  }
};

const OPEN_ORDER_SETTING = 'open_order_setting';
const OPEN_MANAGE_MENUS = 'open_manage_menus';
const OPEN_ORDERS = 'open_orders';

const getAppManifestImpl = () => {
  let appDescriptor;
  if (!experiments.enabled('specs.restaurants.olo-client-manage-apps')) {
    log('Manage My Apps experiment is disabled.');
  } else {
    appDescriptor = {
      mainActions: [
        {
          title: t('online_ordering_editor_app_manager_restaurants_main_CTA'),
          actionId: OPEN_MANAGE_MENUS,
          icon: 'appManager_settingsAction',
        },
      ],
      customActions: [
        {
          title: t('online_ordering_editor_app_manager_quick_action_1'),
          actionId: OPEN_MANAGE_MENUS,
          icon: 'appManager_relatedAppsAction',
          type: 'dashboard',
        },
        {
          title: t('online_ordering_editor_app_manager_quick_action_2'),
          actionId: OPEN_ORDER_SETTING,
          icon: 'appManager_settingsAction',
          type: 'dashboard',
        },
        {
          title: t('online_ordering_editor_app_manager_quick_action_3'),
          actionId: OPEN_ORDERS,
          icon: 'appManager_pagesAction',
          type: 'dashboard',
        },
      ],
      defaultActions: {
        upgrade: {
          upgradeType: 'SITE_UPGRADE',
          upgradeText: t('online_ordering_editor_app_manager_upgrade_text'),
          upgradeLinkText: t('online_ordering_editor_app_manager_upgrade_link_text'),
        },
        learnMoreKB: 'a1cedc92-d3f6-4937-a0b5-4387abb463e0',
      },
    };
  }
  return { appDescriptor };
};

interface HandlerParams<PayloadType = any> {
  sdk: EditorSDK;
  payload?: PayloadType;
  appToken: string;
}
interface AppActionPayload {
  actionId: string;
}

async function onEventImpl({ eventType, eventPayload }: PlatformEvent, editorSDK: EditorSDK) {
  switch (eventType) {
    case 'appActionClicked':
      const { actionId } = eventPayload;
      await appActionHandlers?.[actionId]?.({ sdk: editorSDK, payload: eventPayload, appToken });
      break;
    default:
      return;
  }
}

const appActionHandlers: { [key: string]: (params: HandlerParams<AppActionPayload>) => void } = {
  [OPEN_MANAGE_MENUS]: async ({ sdk, appToken: appDefId }) => {
    await sdk.editor.openDashboardPanel(appDefId, { closeOtherPanels: false, url: '/restaurants' });
    return sdk.tpa.app.refreshApp(appDefId);
  },
  [OPEN_ORDERS]: async ({ sdk, appToken: appDefinitionId }) => {
    await sdk.editor.openDashboardPanel(appDefinitionId, { closeOtherPanels: false, url: '/restaurants/orders' });
    return sdk.tpa.app.refreshApp(appDefinitionId);
  },
  [OPEN_ORDER_SETTING]: async ({ sdk, appToken: appDefinitionId }) => {
    await sdk.editor.openDashboardPanel(appDefinitionId, {
      closeOtherPanels: false,
      url: '/restaurants/ordersSettings',
    });
    return sdk.tpa.app.refreshApp(appDefinitionId);
  },
};

const handleActionImpl: EditorPlatformApp['handleAction'] = ({ type, payload }, editorSDK) => {
  switch (type) {
    case 'migrate': {
      return editorSDK.document.transactions.runAndWaitForApproval(appToken, () =>
        editorSDK.document.tpa.add.application(appToken, {
          appDefinitionId: LOYALTY_APP_ID,
        }),
      );
    }
    default:
      break;
  }
};

const platformApp = withMembersArea(
  {
    editorReady: editorReadyImpl,
    getAppManifest: getAppManifestImpl,
    onEvent: onEventImpl,
    handleAction: handleActionImpl,
  },
  {
    disableADI: false,
    installAutomatically: true,
    installAppsAutomatically: true,
    membersAreaApps: membersAreaAppDefIds,
    experimentOptions: {
      name: 'specs.restaurants.olo-client-members-area',
      scope: 'restaurants',
      truthyValue: 'true',
    },
  },
);

export const editorReady = platformApp.editorReady;
export const getAppManifest = platformApp.getAppManifest;
export const onEvent = platformApp.onEvent;
export const handleAction = platformApp.handleAction;
